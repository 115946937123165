import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'nodelist-foreach-polyfill';

if (typeof window.CustomEvent !== 'function') {
  const polyfill = function customEvent(event, params) {
    let evt = document.createEvent('CustomEvent');
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };
  polyfill.prototype = window.Event.prototype;
  window.CustomEvent = polyfill;
}

if (Element && !Element.prototype.matches) {
  let proto = Element.prototype;
  proto.matches =
    proto.matchesSelector ||
    proto.mozMatchesSelector ||
    proto.msMatchesSelector ||
    proto.oMatchesSelector ||
    proto.webkitMatchesSelector;
}

if (Element && !Element.prototype.closest) {
  Element.prototype.closest = function closest(s) {
    let el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
